// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subpage-page-1-js": () => import("./../../../src/pages/subpage/page1.js" /* webpackChunkName: "component---src-pages-subpage-page-1-js" */),
  "component---src-pages-subpage-subsubpage-page-1-js": () => import("./../../../src/pages/subpage/subsubpage/page1.js" /* webpackChunkName: "component---src-pages-subpage-subsubpage-page-1-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

